<template>
  <NotPermission v-if="!authenticated" />
  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs :routes="[{ name: 'Inicio' }, { name: 'Configuraciones' }]" />
    <v-card>
      <v-toolbar color="grey darken-1" dark card>
        <v-toolbar-title>Configuraciones </v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-container fluid grid-list-lg>
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">Seleccione una Categoría </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 2" step="2">Configure los parámetros </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-layout row wrap>
                <v-flex sm4 d-flex>
                  <div style="padding: 0 0.1rem; margin: 15px 0; width: 100%">
                    <v-toolbar color="primary" dark card style="border-radius: 4px 4px 0 0">
                      <v-toolbar-title>Tienda</v-toolbar-title>
                      <v-spacer />
                    </v-toolbar>
                    <v-card style="padding: 25px 25px">
                      <v-btn
                        large
                        block
                        @click="categoria('')"
                        style="margin: 20px 0; padding: 0 25px"
                      >
                        Conf. General
                      </v-btn>
                      <v-btn
                        large
                        block
                        @click="categoria('Patagonia')"
                        style="margin: 20px 0; padding: 0 25px"
                      >
                        Configurar e-mails
                      </v-btn>
                      <v-btn
                        large
                        block
                        @click="categoria('')"
                        style="margin: 20px 0; padding: 0 25px"
                      >
                        Colores
                      </v-btn>
                      <v-btn
                        large
                        block
                        @click="categoria('Cabecera')"
                        style="margin: 20px 0; padding: 0 25px"
                      >
                        Mensaje cabecera
                      </v-btn>
                      <v-btn
                        block
                        @click="categoria('Footer_info')"
                        large
                        style="margin: 20px 0; padding: 0 25px"
                      >
                        Footer
                      </v-btn>
                      <v-btn
                        block
                        @click="categoria('Redes_sociales')"
                        large
                        style="margin: 20px 0; padding: 0 25px"
                      >
                        Redes sociales
                      </v-btn>
                    </v-card>
                  </div>
                </v-flex>
                <v-flex sm4 d-flex>
                  <div style="padding: 0 0.1rem; margin: 15px 0">
                    <v-toolbar color="primary" dark card style="border-radius: 4px 4px 0 0">
                      <v-toolbar-title>Medios de pago</v-toolbar-title>
                      <v-spacer />
                    </v-toolbar>
                    <v-card style="padding: 25px 25px">
                      <v-btn
                        large
                        block
                        @click="categoria('Transbank-webpay')"
                        style="margin: 20px 0; padding: 0 25px"
                      >
                        Transbank - Webpay
                      </v-btn>
                      <v-btn block @click="categoria('Linkify')" large>
                        Linkify - Transferencias
                      </v-btn>
                    </v-card>
                    <v-toolbar
                      color="primary"
                      dark
                      card
                      style="border-radius: 4px 4px 0 0; margin-top: 12px"
                    >
                      <v-toolbar-title>Boleta y Factura</v-toolbar-title>
                      <v-spacer />
                    </v-toolbar>
                    <v-card style="padding: 25px 25px">
                      <v-btn
                        large
                        block
                        @click="categoria('Lioren')"
                        style="margin: 20px 0; padding: 0 25px"
                      >
                        Lioren - Boletas
                      </v-btn>
                    </v-card>
                  </div>
                </v-flex>
                <v-flex sm4 d-flex>
                  <div style="padding: 0 0.1rem; margin: 15px 0">
                    <v-toolbar color="primary" dark card style="border-radius: 4px 4px 0 0">
                      <v-toolbar-title>Courier</v-toolbar-title>
                      <v-spacer />
                    </v-toolbar>
                    <v-card style="padding: 25px 25px">
                      <v-btn
                        large
                        block
                        @click="categoria('Chilexpress')"
                        style="margin: 20px 0; padding: 0 25px"
                      >
                        Chilexpress
                      </v-btn>
                    </v-card>
                    <v-toolbar
                      color="primary"
                      dark
                      card
                      style="border-radius: 4px 4px 0 0; margin-top: 12px"
                    >
                      <v-toolbar-title>CRM</v-toolbar-title>
                      <v-spacer />
                    </v-toolbar>
                    <v-card style="padding: 25px 25px">
                      <v-btn
                        large
                        block
                        @click="categoria('Hubspot')"
                        style="margin: 20px 0; padding: 0 25px"
                      >
                        Hubspot
                      </v-btn>
                    </v-card>
                  </div>
                </v-flex>
              </v-layout>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-layout row wrap>
                <v-flex sm6 style="margin-bottom: 15px">
                  <v-text-field
                    v-model="search"
                    box
                    append-icon="search"
                    label="Buscar"
                    clearable
                    hide-details
                  />
                </v-flex>
              </v-layout>
              <v-data-table
                :headers="[
                  { text: 'Categoria', value: 'categoria' },
                  { text: 'Aplicacion', value: 'aplicacion' },
                  { text: 'Nombre', value: 'nombre' },
                  { text: 'Valor', value: 'valor' },
                  { text: 'Acciones' },
                ]"
                :items="conf_list"
                :search="search"
                :loading="loadingConfiguraciones"
                :rows-per-page-items="[50]"
                class="elevation-1"
              >
                <tr slot="items" slot-scope="props">
                  <td class="px-3" style="max-width: 100px; overflow: hidden">
                    {{ props.item.categoria }}
                  </td>
                  <td class="px-3" style="max-width: 100px; overflow: hidden">
                    {{ props.item.aplicacion }}
                  </td>
                  <td class="px-3" style="max-width: 150px; overflow: hidden">
                    {{ props.item.nombre }}
                  </td>
                  <td class="px-3" style="max-width: 150px; overflow: hidden; display: table-cell">
                    {{ props.item.valor }}
                  </td>
                  <td class="px-3">
                    <v-btn
                      class="ma-0"
                      :to="{
                        name: 'configuracion',
                        params: { id: props.item.id },
                      }"
                      small
                      icon
                      flat
                      color="info"
                    >
                      <v-icon small> edit </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </v-data-table>
              <v-btn @click="e1 = 1" color="error" large block style="width: 100%; margin-top: 25px"
                >Volver</v-btn
              >
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  metaInfo() {
    return { title: "Configuración de Tienda" };
  },

  components: {
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
    NotPermission: () => import("@/views/errors/NotPermission"),
    ModalDelete: () => import("@/views/cities/ModalDelete"),
  },
  data() {
    return {
      search: "",
      conf_list: [],
      e1: 0,
    };
  },

  computed: {
    ...mapState({
      configuraciones: (state) => state.configuracion.configuraciones,
      loadingConfiguraciones: (state) => state.configuracion.loadingConfiguraciones,
    }),
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user",
    }),
  },
  created() {
    this.getConfiguraciones();
    this.conf_list = this.configuraciones;
  },
  methods: {
    ...mapActions({
      getConfiguraciones: "configuracion/getConfiguraciones",
    }),
    categoria(tipo) {
      if (tipo === "Redes_sociales") {
        this.conf_list = this.configuraciones.filter((conf) => conf.categoria == 'RRSS');
      } else {
        this.conf_list = this.configuraciones.filter((conf) => conf.aplicacion == tipo);
      }

      this.e1 = 2;
    },
  },
};
</script>
